/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  background-repeat: no-repeat;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: 'Roboto', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }



.wrapper {
    background-color: #000;
    text-align: center;
    position: relative;
    overflow: hidden; /* スクロールさせない */
}

.content{
  height:100%;
  position: relative;
}

.content-current{
  position: relative;
  height: 100%;
}

.content-creator{
  height: 120px;
  width: 100%;
  padding: 0 10px 0 10px;
  position: absolute;
  bottom: 0;
}

.content-prev, .content-next, .music-next, .music-prev {
  position: absolute;
  width: 100%;
}

.content-prev , .music-prev{
  opacity: 0;
}

.next-tn, .prev-tn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.next-tn img, .prev-tn img {
  width : 100%;
}

.content-display{
  background-position: center center;
  background-size: cover;
  height: 100%;

  position: absolute;
  left: 50%;
  transform:  translateX(-50%); /*要素の大きさの半分ずつを戻す*/
  -webkit-transform: translateX(-50%);
  width: 100%;
  video{
    width: 100%;
  }
  img{
    width: 100%;
  }
}

/*.content-display{
  position: absolute;
  top: 50%; 
  left: 50%;  
  transform: translateY(-50%) translateX(-50%); 
  -webkit-transform: translateY(-50%) translateX(-50%);
  width: 100%;
}*/

.content-touch{
  height: 100%;
  width: 100%;
  padding: 0 10px 0 10px;
}

.content-music, .content-video{
  display: inline-block;
  float: left;
  width: 50%;
  height: 100%;
  position: relative;
}

.content-music{
  width: 50%;
}

.content-video{
  width: 50%;
}

.content-music{
  /*background-color: #1b1b1b;*/
}

.content-video{
 /* background-color: #232323;*/
}

.next-video-boundary, .prev-video-boundary{
  width:100%;
  height: 59px;
  position: absolute;
  bottom: 60px;
  right : 10px;
}

.next-music-boundary, .prev-music-boundary{
  width:100%;
  height: 72px;
  position: absolute;
  bottom: 55px;
  left : 10px;
}

.music-boundary{
  width:85%;
  height: 59px;
  position: absolute;
  bottom: 60px;
  left: 10px;
}

.video-boundary{
  width:15%;
  height: 59px;
  position: absolute;
  bottom: 60px;
  right:10px;
}

.music-boundary-main{
  height: 59px;
}

.music-boundary-icon{
  float: left;
  width: 59px;
  height: 59px;
  background-image: url("");
  background-size: cover;
  border-radius: 50%;
  background-position: center;
}

.music-boundary-info{
  float: left;
  display: inline-block;
  text-align: left;
  overflow:hidden;
  padding-left: 6px;
  width: 236px;
}

.music-boundary-text{
  text-align: left;
  line-height: 1.3;
  color: #fff;
  overflow: hidden;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 245px, rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
  white-space: nowrap;
}

.music-boundary-name{
    display: inline-block;
}

.music-boundary-title{
  font-weight: 600;
  background-size: 12px;
  background-position: 0;
  display: inline-block;
}

.music-boundary-label {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.66) 245px, rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
}

.music-boundary-meta{
  height: 15px;
  color: #fff;
  font-size: 12px;
  margin-top: 4px;
  white-space: nowrap;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.66) 245px, rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
}

.music-boundary-play{
  float: left;
  padding-left: 12px;
  margin-right: 10px;
  background-size: 10px;
  background-position: 0 center;
}

.music-boundary-fav{
  padding-left: 15px;
  float: left;
  background-size: 14px;
  background-position: 0 center;

}


.video-boundary {

}

.video-boundary-main{
  height: 59px;
}

.video-boundary-icon{
  float: right;
  width: 39px;
  height: 39px;
  background-image: url();
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  margin-top: 20px;
}

.video-boundary-info{
  float: right;
  display: inline-block;
  text-align: left;
  padding-right: 6px;
  width:0;
}

.video-boundary-text{
  text-align: right;
  padding-right: 4px;
  padding-top: 4px;
  line-height: 1.3;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 245px, rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
}

.video-boundary-label {
  font-size: 12px;
  opacity: 66%;
  display: none;
}

.video-boundary-name{
  display: inline-block;
}

.video-boundary-title{
  font-weight: 600;
  display: inline-block;
}

.video-boundary-meta{
  height: 15px;
  padding-right: 5px;
  color: #fff;
  font-size: 12px;
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 245px, rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
}

.video-boundary-play{
  float: right;
  padding-left: 12px;
  margin-right: 10px;
  background-size: 10px;
  background-position: 0 center;
}

.video-boundary-fav{
  padding-left: 15px;
  float: right;
  background-size: 14px;
  background-position: 0 center;

}

.menu{
  background-color: #000;
  border-top: 1px solid #262626;
  height :50px;
  position:fixed;
  bottom: 0;
  width: 100%;
  .menu-boundary {
    width: 33%;
    height: 100%;
    display: inline-block;
    float: left;
    background-repeat: no-repeat;
    background-position: center 10px;
    position: relative;
    font-size: 10px;
  }
  .menu-text{
    position: absolute;
    bottom:3px;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
  .menu-home{
  }
  .menu-search{
  }
  .menu-account{
  }
}

.header{
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  font-size: 18px;
  color: #fff; 
  padding: 12px 10px 0 10px;
}

.header-category {
  float: left;
}

.header-right{
  font-size: 10px;
  float: right;
  width: 180px;
}

.header-right-boundary {
  display: inline-block;
  float: right;
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center 6px;
  position: relative;
  font-size: 24px;
  margin-left: 5px;
}

.header-right-text{
  font-size: 10px;
  position: absolute;
  top:34px;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  width: 100%;
}

.header-fav{
}

.header-share{
}

.header-icon img{
  width: 34px;
}

.header-left{
  font-size: 10px;
  float: left;
  width: 60px;
  margin-top: 0px;
}

.header-left-boundary{
  display: inline-block;
  float: left;
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center 6px;
  position: relative;
  font-size: 24px;
  margin-right: 5px;
  margin-top: 6px;
}

.header-menu{
  background-position: center;
  margin-right: 10px;
  font-size: 22px;
}

.header-left-text{
  font-size: 10px;
  position: absolute;
  top: 34px;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  width: 100%;
}

.content-style{
  position: absolute;
  bottom: 15px;
  font-size: 16px;
  width: 100%;
  padding: 0 10px 4px 10px;
}

.content-style-music {
  text-align-last: left;
  display: inline-block;
  float: left;
}

.content-style-music-label{
  display: inline-block;
  color: #000;
  background: #fff;
  font-size: 66%;
  padding: 1px 2px;
  line-height: 1.4;
}

.content-style-video-label {
  display: inline-block;
  color: #000;
  background: #fff;
  font-size: 66%;
  padding: 1px 2px;
  line-height: 1.4;
  float: right; 
  margin: 6px 0 0 4px;
}

.content-style-music-text {
  display: inline-block;
  vertical-align: -2px;
}

.content-style-video-text{
  display: inline-block;
  vertical-align: -2px;
  float: right;
  opacity: 0;
  margin-top: 1px;
}

.content-style-video {
  text-align-last: right;
  display: inline-block;
  float: right;
  opacity: .33;
}

.menu-wrapper{
  background-color: #0E0D12;
  opacity: 0;
  position: absolute;
  top: 0;
  display: block;
  padding: 20px 10px;
  .menu-wrapper-header-icon{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="20,20"><path fill="%23fff" d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21v-2z" /></svg>');
    margin-right: 10px;
    font-size: 22px;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  .menu-wrapper-header-text{
    font-size: 16px;
    font-weight: 100;
    vertical-align: 0.1em;
  }
  .menu-wrapper-body {
    margin-top: 35px;
    ul {
      padding: 0;
      font-weight: 100;
      li{
        list-style-type: none;
        margin-bottom: 18px;
      }
    }
  }
}

.wall {
  position: absolute;
  background: #0E0D12;
  top: 0;
  opacity: 0.8;
  display: none;
}

.music-detail {
  position: absolute;
  background: #0E0D12;
  display: none;
}
.music-detail-header{
  padding:0 10px;
  margin-top: 40px;
}
.music-detail-close{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.music-style-detail {
  position: absolute;
  background: #0E0D12;
  display: none; 
}
.music-style-detail-header{
  padding:0 10px;
  margin-top: 40px;
}
.music-style-detail-close{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.edge-bottom-shadow {
  height: 116px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(54,54,73,0.20) 60%, rgba(81,81,110,0) 100%);
}

.edge-top-shadow {
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(54,54,73,0.2) 40%, rgba(81,81,110,0) 100%);
}

.text-shadow-01 {
  -moz-text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  -ms-text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}